import React from 'react'
import Layout from '../components/Layout'

export default ({ location }) => (
  <Layout location={location} type='error404'>
    <div className="error-404 not-found">
      <header className="page-header">
        <h1 className="page-title">Oops! That page can’t be found.</h1>
      </header>
      <div className="page-content">
        <p>It looks like nothing was found at this location.</p>
      </div>
    </div>
  </Layout>
)

